@tailwind base;
@tailwind components;
@tailwind utilities;

html, body {
  height: 100%;
}

#root, main {
  min-height: 100vh;
}