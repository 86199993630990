.item-container {
    @apply border border-chinese-silver
    flex flex-row items-center justify-start gap-2
    bg-white p-2 rounded-xl cursor-pointer
}

.item {
    @apply bg-light-cobalt-blue
    flex items-center justify-center
    font-bold text-2xl text-white
    w-12 h-12 rounded-full;
}